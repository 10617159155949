import { useTheme } from '@emotion/react'
import {
  encodeQuery,
  getServerSideMetaData,
  MetaDataProps,
} from 'fitify-ui-onboarding/src/utils'
import { GetServerSideProps } from 'next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { SSRConfig } from 'next-i18next/dist/types/types'

import { Layout } from 'components/Layout'
import { PageTitle } from 'components/PageTitle'
import { getCommonServerSideProps } from 'utils/get-common-server-side-props'
import { APP_PAGES, ONBOARDING_PAGES } from 'utils/routes'

// Dynamic page imports
const HomeV2 = dynamic(() => import('sections/onboarding/digitalV2/home'))
const HomeV3 = dynamic(() => import('sections/onboarding/digitalV3/home'))
const HomeV4 = dynamic(() => import('sections/onboarding/digitalV4/home'))

export const getServerSideProps: GetServerSideProps = async (context) => {
  const promoCode = context.query.promo as string

  return {
    props: {
      ...(await getCommonServerSideProps(context)),
      metaData: getServerSideMetaData(promoCode),
    },
  }
}

type SSRConfigType = Awaited<
  Promise<
    | SSRConfig
    | {
        notFound: boolean
      }
  >
>

export default function Page({
  metaData,
  _nextI18Next,
}: {
  metaData: MetaDataProps
  _nextI18Next?: Extract<SSRConfigType, SSRConfig>['_nextI18Next'] // Needed for localized image sources to match server rendered image source with client's locale
}) {
  const { t } = useTranslation()
  const theme = useTheme()
  const router = useRouter()

  const actions = {
    handleContinue: () => {
      router.push(ONBOARDING_PAGES.gender.path)
    },
    handleLogin: () => {
      router.push(
        encodeQuery(APP_PAGES.login.path, {
          next: ONBOARDING_PAGES.gender.path,
        })
      )
    },
  }

  return (
    <Layout
      options={{
        isPrevHidden: true,
      }}
      seo={{
        description: t('onboarding_home_meta_description'),
      }}
    >
      <PageTitle title={t('welcome_title')} metaData={metaData} />

      {theme.digitalV2 && (
        <HomeV2
          initialLocale={_nextI18Next?.initialLocale}
          t={t}
          actions={actions}
        />
      )}
      {theme.digitalV3 && (
        <HomeV3
          initialLocale={_nextI18Next?.initialLocale}
          t={t}
          actions={actions}
        />
      )}
      {theme.digitalV4 && (
        <HomeV4
          initialLocale={_nextI18Next?.initialLocale}
          t={t}
          actions={actions}
        />
      )}
    </Layout>
  )
}
